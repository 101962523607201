<template>
  <section id="nav-panel" class="login-panel">
    <div @keydown.enter="login">
      <!-- <img class="logo" src="@/assets/images/logo.svg" alt="Homes England" /> -->
      <h1>{{appTitle}} Login</h1>
      <p>Log in to get instant access to pin point</p>
      <form>
        <div>
          <input
            v-model="username"
            type="email"
            name="email"
            placeholder="example@email.com"
            required
          />
        </div>
        <div>
          <input v-model="password" type="password" name="password" placeholder="password" required />
        </div>
      </form>
      <div class="button-wrap">
        <button @click="login" class="btn-login">

          <span v-if="loginStatus == true">Loading</span>
          <span v-else>Login</span>
        </button>
        <!-- <button class="btn-help"></button> -->
      </div>
      <p class="request">
        Don’t have a log in?<br>
        <a :href="'mailto:' + siteAdmin">Click here to request access</a>
      </p>
    </div>
  </section>
</template>

<script>
import firebase from "firebase";
import Settings from "@/mixins/AppSettings.js";

export default {
  name: "LoginPanel",
  data() {
    return {
      username: "",
      password: "",
      loginStatus: false,
      appTitle: Settings.appTitle,
      siteAdmin: Settings.siteAdmin
    };
  },
  mounted() {},
  methods: {
    async login() {
      let self = this;

      this.loginStatus = true;



      try {
        let user = await firebase
          .auth()
          .signInWithEmailAndPassword(this.username, this.password);

        if (user) {
          this.$router.push("site/homePage")
        } else {
          this.loginStatus = false;
        }
      } catch (err) {
        M.toast(
          {
            html: err.message
          },
          1000
        );
        this.loginStatus = false;
      }
    }
  }
};
</script>